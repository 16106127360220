<template>
    <loader
        :listen="[
            'auth/auth',
            `partner/getPartner/${partnerCode}`,
            `result/getAllReferredResultsByPartner/${partnerCode}`,
            `order/getAllReferredOrdersByPartner/${partnerCode}`,
            'result/getResult'
        ]"
        class="pt-3 pb-5">
        <div v-if="partnerCode && partnerToUse" style="position: relative">
            <div v-can-access.role="'doctor admin partner-plus'" class="text-left pt-4">
                <div class="pb-3 w-100 d-flex flex-column flex-sm-row align-items-start justify-content-between">
                    <h4 class="text__page-header mt-2 w-100 d-flex justify-content-start">
                        <img :src="partnerToUse.profilePictureUrl" class="rounded-circle mr-2" width="40" height="40" />
                        {{ partnerToUse.name }}
                    </h4>
                </div>
                <div class="mb-3">
                    <div class="text-muted"><b>Partner Linking Code:</b> {{ partnerToUse.partnerCode || 'No linking code yet' }}</div>
                    <div class="text-muted">
                        <b>Partner Coupon Codes:</b>
                        {{
                            partnerToUse.metadata?.partnerCouponsCodes?.map((cp) => `${cp.code} (${cp.percentage}% off)`).join(', ') ||
                            'No coupon code'
                        }}
                    </div>
                    <div class="text-muted mb-2"><b>Email:</b> {{ partnerToUse.email }}</div>
                    <span
                        v-if="partnerToUse.canDoDoctorReview"
                        class="badge badge-pill badge-info text-nowrap"
                        style="font-size: 0.75rem; padding: 0.2rem 0.4rem">
                        DOES DOCTOR REVIEW
                    </span>
                    <span
                        v-if="partnerToUse.canDoPartnerApproval"
                        class="badge badge-pill badge-info text-nowrap"
                        style="font-size: 0.75rem; padding: 0.2rem 0.4rem">
                        DOES RESULT APPROVAL AFTER DOCTOR REVIEW
                    </span>
                    <span
                        v-if="!partnerToUse.canDoDoctorReview && !partnerToUse.canDoPartnerApproval"
                        class="badge badge-pill badge-info text-nowrap"
                        style="font-size: 0.75rem; padding: 0.2rem 0.4rem">
                        BASIC PARTNER. NO REVIEW/APPROVAL INCLUDED
                    </span>
                </div>
                <div class="card mb-3">
                    <div class="card-inner border-0 p-4">
                        <h6 class="d-flex justify-content-center align-items-center m-0 justify-content-md-start mb-2">Referred results</h6>
                        <ResultsTable
                            :results="resultsList"
                            :without-filters="true"
                            @pageChange="onResultsPageChange"
                            :show-no-results-image="false" />
                        <h6
                            v-can-access.role="'admin partner-plus'"
                            class="d-flex justify-content-center align-items-center m-0 justify-content-md-start mb-2">
                            Referred orders
                        </h6>
                        <OrdersTable
                            v-can-access.role="'admin partner-plus'"
                            :orders="ordersList"
                            :without-filters="true"
                            @pageChange="onOrdersPageChange"
                            @viewResult="viewResult" />
                    </div>
                </div>
                <div></div>
            </div>
        </div>
    </loader>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ResultsTable from '@/components/results-table.vue';
import OrdersTable from '@/components/orders-table.vue';

export default {
    components: {
        ResultsTable,
        OrdersTable
    },
    data() {
        return {
            order: null,
            partnerToUse: null
        };
    },
    computed: {
        ...mapGetters({
            allUsers: 'user/allUsers',
            user: 'user/currentUser',
            result: 'result/currentResult',
            allPartnerResults: 'result/allPartnerResults',
            allPartnerOrders: 'order/allPartnerOrders',
            resultPartnerPagination: 'result/partnerPagination',
            orderPartnerPagination: 'order/partnerPagination'
        }),
        partnerCode() {
            return this.$route?.params?.partnerCode;
        },
        resultsList() {
            return _.values(this.allPartnerResults[this.partnerToUse.partnerCode]);
        },
        ordersList() {
            return _.values(this.allPartnerOrders[this.partnerToUse.partnerCode]);
        },
        anyResult() {
            const partnerResults = this.allPartnerResults[this.partnerToUse.partnerCode];

            return !!partnerResults.length;
        },
        resultPagination() {
            return this.resultPartnerPagination(this.partnerToUse.partnerCode);
        },
        orderPagination() {
            return this.orderPartnerPagination(this.partnerToUse.partnerCode);
        }
    },
    async created() {
        const resultId = this.$route.params.resultId || this.$route.query.resultId || this.$route.params.id;

        this.partnerToUse = _.find(this.allUsers, (partner) => partner.partnerCode === this.partnerCode);

        if (!this.partnerToUse) {
            await this.getPartner(this.partnerCode);
            this.partnerToUse = _.find(this.allUsers, (partner) => partner.partnerCode === this.partnerCode);
        }

        const promises = [];

        if (this.user.isAdmin || this.user.isPartnerPlus) {
            if (resultId && (!this.result || _.isEmpty(this.result) || this.result.id != resultId)) {
                promises.push(this.getResultWithHistoricalData({ resultId: resultId }));
            }

            if (this.partnerToUse) {
                promises.push(this.getAllReferredResultsByPartner({ partnerCode: this.partnerToUse.partnerCode }));
            }
        }

        if (this.user.isAdmin || this.user.isPartnerPlus) {
            promises.push(this.getAllReferredOrdersByPartner({ partnerCode: this.partnerToUse.partnerCode }));
        }

        await Promise.all(promises);
    },
    methods: {
        ...mapActions({
            getResultWithHistoricalData: 'result/getResultWithHistoricalData',
            getAllReferredResultsByPartner: 'result/getAllReferredResultsByPartner',
            getAllReferredOrdersByPartner: 'order/getAllReferredOrdersByPartner',
            getPartner: 'partner/getPartner'
        }),
        viewResult(order) {
            this.$router.push({ name: 'result', params: { id: order.orderId }, query: { idType: 'order', userId: order.userId } });
        },
        async onResultsPageChange({ page, queryString }) {
            await this.getAllReferredResultsByPartner({
                partnerCode: this.partnerToUse.partnerCode,
                page,
                pageSize: this.resultPagination.pageSize,
                queryString
            });
        },
        async onOrdersPageChange({ page, queryString }) {
            await this.getAllReferredOrdersByPartner({
                partnerCode: this.partnerToUse.partnerCode,
                page,
                pageSize: this.orderPagination.pageSize,
                queryString
            });
        }
    }
};
</script>
<style lang="scss">
.card-body {
    width: 100% !important;
}

.doctor-comment {
    display: flex !important;
    align-items: start !important;
}
</style>
