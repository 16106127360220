/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios from 'axios';
import client from './client';
import { getInstance } from '../../utils/auth.instance';
import { notify } from '@/utils/notify';

let apiDomain = process.env.VUE_APP_API_URL;
apiDomain = apiDomain[apiDomain.length - 1] === '/' ? apiDomain : `${apiDomain}/`;

axios.defaults.withCredentials = true;

const instance = axios.create({
    baseURL: apiDomain,
    withCredentials: true
});

instance.interceptors.response.use(
    (response) => {
        const errorData = _.get(response, 'data.error', null);
        const errorMessage = _.get(response, 'error.message', null);
        const hideToast = !!response.config.hideToast;

        if (errorData || errorMessage) {
            if (!hideToast) {
                const message = errorData || errorMessage;
                // Only show toast if hideToast is true
                notify({
                    type: 'error',
                    text: message
                });
            }

            throw new Error(errorData || errorMessage);
        }

        if (response.data?.message && !hideToast) {
            notify({
                type: 'success',
                text: _.capitalize(response.data.message)
            });
        }

        return response;
    },
    (error) => {
        const hideToast = !!error?.response?.config?.hideToast;

        if (error.response?.data?.logout) {
            setTimeout(() => (window.location.href = '/account/logout'), 150);
        }

        if (!hideToast && (error.response?.data?.error || error?.message || error?.response?.data?.detail || error?.response?.data?.message)) {
            const message = error?.response?.data?.message || error?.response?.data?.detail || error.response?.data?.error || error?.message;

            notify({
                type: 'error',
                text: message
            });
        }

        throw error;
    }
);

export default {
    async get(method, customHeaderObject = null, handleAsBlob = false) {
        const requestConfig = {
            headers: populateHeader(customHeaderObject)
        };

        if (handleAsBlob) {
            requestConfig.responseType = 'blob';
        }

        const res = await instance.get(`${apiDomain}${method}`, requestConfig);
        return client.parseResponse(res);
    },
    async post(method, body = null, customHeaderObject = null, handleAsBlob = false) {
        const requestConfig = {
            headers: populateHeader(customHeaderObject)
        };

        if (handleAsBlob) {
            requestConfig.responseType = 'blob';
        }

        const res = await instance.post(method, body, requestConfig);
        return client.parseResponse(res);
    },
    async put(method, body = null, customHeaderObject = null) {
        const res = await instance.put(method, body, { headers: populateHeader(customHeaderObject) });

        return client.parseResponse(res);
    },
    async delete(method, customHeaderObject = null) {
        const res = await instance.delete(method, { headers: populateHeader(customHeaderObject) });

        return client.parseResponse(res);
    },
    async getStatus(customHeaderObject = null) {
        const res = await instance.get(`signup-status`, { headers: populateHeader(customHeaderObject) });

        return client.parseResponse(res);
    },
    async auth(customHeaderObject = null) {
        try {
            const res = await instance.post(`auth`, {}, { headers: populateHeader(customHeaderObject) });

            return client.parseResponse(res);
        } catch (error) {
            console.log('aici???');
            return error;
        }
    }
};

const populateHeader = (customHeaderObject = null) => {
    const authInstance = getInstance();
    const accessToken = authInstance?.authState?.user?.accessToken;

    const header = {
        Authorization: `Bearer ${accessToken}`
    };

    if (customHeaderObject) {
        _.forEach(customHeaderObject, (value, key) => {
            if (_.isBoolean(value)) {
                header[key] = value ? 1 : 0;
            } else {
                header[key] = value;
            }
        });
    }

    return header;
};
