/* eslint-disable no-unused-vars */
import api from './api';

export default {
    async getMetrics(filter, partnerCode = null, exportToCSV = false) {
        let queryString = '';

        if (filter) {
            queryString = `filter=${JSON.stringify(filter)}`;
        }

        if (partnerCode) {
            queryString += `${queryString ? '&' : ''}partnerCode=${partnerCode}`;
        }

        if (exportToCSV) {
            queryString += `${queryString ? '&' : ''}exportToCSV=${exportToCSV}`;
        }

        const response = await api.get(`dashboard/metrics?${queryString}`);

        return response;
    }
};
