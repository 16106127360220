<template>
    <div>
        <div class="w-100 d-flex flex-column flex-sm-row align-items-start justify-content-between">
            <h4 class="text__page-header mt-2 d-flex justify-content-start">Partner Management</h4>
        </div>
        <div v-can-access.role="'admin'" class="d-flex flex-column flex-sm-row align-items-center justify-content-between pb-3">
            <!-- Add Partner Button -->
            <button
                v-can-access.role="'admin'"
                @click="showAddPartnerModal = !showAddPartnerModal"
                class="btn btn-primary mb-2 mb-sm-0 flex-shrink-0">
                Add Partner
            </button>

            <!-- Date Range Picker and Export Button -->
            <div class="d-flex flex-column flex-sm-row align-items-center justify-content-between w-100 w-sm-auto">
                <!-- Date Range Picker -->
                <div class="mr-sm-3 w-100 w-sm-auto flex-grow-1">
                    <date-range
                        :range="range"
                        @change="onDateRangeChange"
                        :disabled="isExporting"
                        :loading="isExporting"
                        :applyButtonText="isExporting ? 'Exporting...' : 'Export to CSV'"
                        :showApplyByDefault="true" />
                </div>
            </div>
        </div>

        <div v-if="loggedUser.isAdmin">
            <div class="row justify-content-md-center flex-column mx-0">
                <div class="form-group">
                    <users-table
                        :users="allPartners"
                        :role="Roles.PARTNER_PLUS"
                        @userSelected="viewPartnerInfo"
                        @pageChange="onPageChange"
                        @updateUser="
                            (partner) => {
                                partnerToUpdate = partner;
                                showUpdatePartnerModal = !showUpdatePartnerModal;
                            }
                        "
                        @search="onSearchTerm"
                        :page="page"
                        :pageSize="pageSize"
                        :total="total"
                        :queryString="queryString"
                        :isLoading="isLoading" />
                </div>
            </div>

            <slot v-if="showAddPartnerModal || showUpdatePartnerModal" for="modals">
                <partner-add-modal
                    v-if="showAddPartnerModal"
                    :show-modal="showAddPartnerModal"
                    @addPartner="onAddPartner"
                    @close="showAddPartnerModal = false" />
                <partner-edit-modal
                    v-if="showUpdatePartnerModal"
                    :show-modal="showUpdatePartnerModal"
                    @updatePartner="onUpdatePartner"
                    @updateCouponCode="onUpdateCouponCode"
                    @close="showUpdatePartnerModal = false"
                    :initialData="partnerToUpdate" />
            </slot>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AdminPortal } from '@frontegg/vue';
import UsersTable from '../components/users-table';
import { Roles } from '@/utils/constants';
import PartnerAddModal from '@/components/Modals/partner-add-modal.vue';
import PartnerEditModal from '@/components/Modals/partner-edit-modal.vue';
import DateRange from '@/components/global/date/date-range.vue';

export default {
    components: { UsersTable, PartnerAddModal, PartnerEditModal, DateRange },
    data() {
        return {
            Roles,
            showAddPartnerModal: false,
            showUpdatePartnerModal: false,
            partnerToUpdate: null,
            dateRange: {
                start: null,
                end: null
            },
            range: {
                value: 'all-time',
                type: 'custom'
            }
        };
    },
    computed: {
        ...mapGetters({
            allPartners: 'user/allPartners',
            loggedUser: 'user/currentUser',
            page: 'user/partnerPage',
            pageSize: 'user/partnerPageSize',
            total: 'user/partnerTotal',
            queryString: 'user/partnerQueryString'
        }),
        isLoading() {
            return !!this.$store.state.loading['user/getAllPartners'];
        },
        isExporting() {
            return this.$store.state.loading['dashboard/exportMetricsToCSV'];
        }
    },
    async created() {
        await this.getAllPartners();
    },
    methods: {
        ...mapActions({
            getAllPartners: 'user/getAllPartners',
            createPartner: 'partner/createPartner',
            updatePartner: 'partner/updatePartner',
            updatePartnerDiscountCode: 'partner/updatePartnerDiscountCode',
            getDashboardBasicMetrics: 'dashboard/getDashboardBasicMetrics'
        }),
        showAdminPortal(url) {
            AdminPortal.show();
            // eslint-disable-next-line no-return-assign
            setTimeout(() => (window.location.href = url), 200);
        },
        viewPartnerInfo(user) {
            this.$router.push({ name: 'partner-info', params: { partnerCode: user.partnerCode } });
        },
        async onPageChange({ page, queryString }) {
            await this.getAllPartners({
                page,
                pageSize: this.pageSize,
                queryString
            });
        },
        async onSearchTerm(searchTerm) {
            await this.getAllPartners({
                page: 1,
                pageSize: this.pageSize,
                queryString: searchTerm
            });
        },
        async onAddPartner(partnerData) {
            try {
                // Dispatch the createPartner action with the emitted partner data
                await this.createPartner(partnerData);

                this.$notify({
                    type: 'success',
                    text: 'Partner added successfully'
                });

                this.showAddPartnerModal = false;
            } catch (error) {
                console.error('Error adding partner:', error);
            }
        },
        async onUpdatePartner(partnerData) {
            try {
                // Dispatch the createPartner action with the emitted partner data
                partnerData.id = this.partnerToUpdate.id;
                await this.updatePartner(partnerData);

                this.$notify({
                    type: 'success',
                    text: 'Partner updated successfully'
                });

                this.showUpdatePartnerModal = false;
                this.partnerToUpdate = null;
            } catch (error) {
                console.error('Error updating partner:', error);
            }
        },
        async onUpdateCouponCode({ operation, code }) {
            try {
                await this.updatePartnerDiscountCode({
                    id: this.partnerToUpdate.id,
                    discount: { code },
                    operation
                });

                if (operation === 'create') {
                    if (!this.partnerToUpdate.metadata.partnerCouponsCodes) {
                        this.partnerToUpdate.metadata.partnerCouponsCodes = [];
                    }

                    this.partnerToUpdate.metadata.partnerCouponsCodes.push({
                        code,
                        percentage: 15
                    });
                } else if (operation === 'delete') {
                    this.partnerToUpdate.metadata.partnerCouponsCodes = this.partnerToUpdate.metadata.partnerCouponsCodes.filter(
                        (existingCode) => existingCode.code !== code
                    );
                }

                this.$notify({
                    type: 'success',
                    text: `Coupon code ${operation === 'create' ? 'added' : 'removed'} successfully`
                });
            } catch (error) {
                console.error('Failed to update coupon code:', error);

                this.$notify({
                    type: 'error',
                    text: `Failed to ${operation === 'create' ? 'add' : 'remove'} coupon code`
                });
            }
        },
        async exportMetricsToCSV() {
            try {
                const csvContent = await this.getDashboardBasicMetrics({
                    filter: this.range,
                    exportToCSV: true
                });

                if (csvContent) {
                    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                    const link = document.createElement('a');
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', `dashboard_metrics_${new Date().toISOString()}.csv`);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            } catch (error) {
                console.error('Error exporting metrics to CSV:', error);
                this.$notify({
                    type: 'error',
                    text: 'Failed to export metrics. Please try again.'
                });
            }
        },
        onDateRangeChange(range) {
            this.range = range;

            this.exportMetricsToCSV();
        }
    }
};
</script>
