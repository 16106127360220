<template>
    <div class="w-100 d-flex flex-column justify-content-center" :class="align === 'right' ? 'justify-content-md-end' : 'justify-content-md-start'">
        <button
            v-if="summarized"
            class="btn btn-filter d-flex justify-content-center"
            :class="align === 'right' ? 'justify-content-md-end' : 'justify-content-md-start'"
            @click.prevent="enabled = !enabled">
            <span :class="enabled ? '' : 'primary'">{{ getFilterLabel }} <b-icon icon="funnel-fill" /> </span>
        </button>
        <div>
            <label v-if="label" for="select">
                {{ label }}
            </label>
            <div
                v-if="!summarized || enabled"
                :class="`d-flex justify-content-center align-items-center ${filterType === 'custom' ? 'mb-2' : ''}
                    ${align === 'right' ? 'justify-content-md-end' : 'justify-content-md-start'}`">
                <div>
                    <select id="select" v-model="filterType" :disabled="isDisabled" name="select" class="custom-select mr-2" required>
                        <option value="all-time">All Time</option>
                        <option value="last-2-weeks">Last 2 Weeks</option>
                        <option value="last-30-days">Last 30 Days</option>
                        <option value="this-month">This Month</option>
                        <option value="last-month">Last Full Month</option>
                        <option value="last-3-months">Last 3 Months</option>
                        <option value="custom">Custom</option>
                    </select>
                </div>
                <div>
                    <loading-btn
                        v-if="showApplyButton"
                        class="btn btn-sm btn-primary ml-2"
                        :listen="'user/updateProfile'"
                        :force-loading="loading"
                        @click.prevent="apply">
                        {{ applyButtonText }}
                    </loading-btn>
                </div>
            </div>
            <date-picker v-if="(!summarized || enabled) && filterType == 'custom'" v-model="usedRange" :masks="masks" :max-date="maxDate" is-range>
                <template #default="{ inputValue, inputEvents }">
                    <div
                        class="d-flex flex-column flex-sm-row justify-content-center align-items-center"
                        :class="`${align === 'right' ? 'justify-content-md-end' : 'justify-content-md-start'}`">
                        <input
                            class="px-3 py-1 border rounded"
                            :value="inputValue.start"
                            :class="isSm ? '' : 'mr-1'"
                            :disabled="filterType != 'custom' || isDisabled"
                            v-on="inputEvents.start" />
                        <span>
                            <b-icon icon="arrow-right" />
                        </span>
                        <input
                            class="px-3 py-1 border rounded"
                            :value="inputValue.end"
                            :class="isSm ? '' : 'ml-1'"
                            :disabled="filterType != 'custom' || isDisabled"
                            v-on="inputEvents.end" />
                    </div>
                </template>
            </date-picker>
        </div>
    </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { mapGetters } from 'vuex';

export default {
    components: {
        DatePicker
    },
    props: {
        isDisabled: {
            type: Boolean,
            default: false
        },
        range: {
            type: Object,
            default: {
                start: null,
                end: null,
                type: 'all-time'
            }
        },
        label: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        summarized: {
            type: Boolean,
            default: false
        },
        showTooltipInfo: {
            type: Boolean,
            default: true
        },
        align: {
            type: String,
            default: 'right'
        },
        applyButtonText: {
            type: String,
            default: 'Apply'
        },
        showApplyByDefault: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            usedRange: {
                start: this.range?.type === 'date' ? this.range.start : new Date(new Date().setDate(new Date().getDate() - 15)),
                end: this.range?.type === 'date' ? this.range.end : new Date()
            },
            masks: {
                input: 'DD/MM/YYYY'
            },
            showApplyButton: this.showApplyByDefault || false,
            filterType: this.range?.type === 'date' ? 'custom' : this.range.value || 'all-time',
            maxDate: new Date(),
            windowWidth: window.innerHeight,
            enabled: !this.summarized || false
        };
    },
    computed: {
        ...mapGetters({
            user: 'user/currentUser'
        }),
        userProfileUpdating() {
            if (this.showApplyButton) {
                return this.$store.state.loading['user/updateProfile'];
            }

            return false;
        },
        isSm() {
            return this.windowWidth < 577;
        },
        getFilterLabel() {
            switch (this.filterType) {
                case 'all-time':
                    return 'All Time';
                case 'last-30-days':
                    return 'Last 30 Days';
                case 'this-month':
                    return 'This Month';
                case 'last-2-weeks':
                    return 'Last 2 Weeks';
                case 'last-month':
                    return 'Last Full Month';
                case 'last-3-months':
                    return 'Last 3 Months';
                case 'custom':
                    return 'Custom';
                default:
                    return 'All Time';
            }
        }
    },
    watch: {
        usedRange: {
            deep: true,
            handler() {
                this.showApplyButton = true;
            }
        },
        filterType() {
            this.showApplyButton = true;
        },
        enabled() {
            if (!this.enabled) {
                (this.filterType = this.range?.type === 'date' ? 'custom' : this.range.value || 'all-time'),
                    (this.usedRange = {
                        start: this.range.start || new Date(new Date().setDate(new Date().getDate() - 15)),
                        end: this.range.end || new Date()
                    });
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        apply() {
            this.$emit('change', {
                // to adjust later to properly handle and filterType to send just 'date' or 'custom' and then
                // separately we have a filterValue which properly sends thr filterType
                type: this.filterType === 'custom' ? 'date' : 'custom',
                value: this.filterType === 'custom' ? null : this.filterType,
                start: this.filterType !== 'custom' ? null : this.usedRange.start,
                end: this.filterType !== 'custom' ? null : this.usedRange.end
            });

            // this.showApplyButton = false;
        }
    }
};
</script>
