/* eslint-disable no-unused-vars */
import { constructQueryParams } from '@/utils/utils';
import api from './api';

export default {
    async acceptPartnerInvitation({ token, response }) {
        const apiResponse = await api.post(`partner/accept-invitation/${token}`, { response });

        return apiResponse;
    },

    // resource can be 'userId' (for the user, where we pass the id of the user) and 'orderId' (for the order, where we pass the id of the order)
    async createPartnerConnection({ partnerCode, resource, resourceId }) {
        const response = await api.post('partner/create-connection', { partnerCode, resource, resourceId });

        return response;
    },

    async deletePartnerConnectionFromAUser({ partnerCode, userId }) {
        const response = await api.delete(`/user/referrer-partner-code/${partnerCode}?userId=${userId}`);

        return response;
    },

    async getPartner(partnerCode) {
        const response = await api.get(`user/partner-code/${partnerCode}`);

        return response;
    },

    // to do discount percentage later based on back-end implementation
    async createPartner({ name, email, role, actions, partnerCouponCode, discountPercentage, sendInvitationEmail }) {
        try {
            const response = await api.post('partner', {
                name,
                email,
                role,
                actions,
                partnerCouponCode,
                discountPercentage,
                sendInvitationEmail: sendInvitationEmail || false
            });
            return response;
        } catch (error) {
            console.error('Error creating partner:', error);
            throw error;
        }
    },

    // to do discount percentage later based on back-end implementation
    async updatePartner(id, { name, email, role, actions }) {
        const toUpdate = {};

        if (!id) {
            throw new Error('Partner ID is required to update a partner');
        }

        // Add only properties that are defined to the toUpdate object
        if (name !== undefined) toUpdate.name = name;
        if (email !== undefined) toUpdate.email = email;
        if (role !== undefined) toUpdate.roles = [role];
        if (actions !== undefined) toUpdate.actions = actions;
        //if (partnerCouponCode !== undefined) toUpdate.partnerCouponCode = partnerCouponCode;

        try {
            const response = await api.put(`partner/${id}`, toUpdate);

            return response;
        } catch (error) {
            console.error('Error updating partner:', error);
            throw error;
        }
    },

    async updatePartnerDiscountCode({ id, discount, operation }) {
        if (!id) {
            throw new Error('Partner ID is required to update a discount code');
        }

        if (!discount || !operation) {
            throw new Error('Both discount and operation are required to update a discount code');
        }

        try {
            const response = await api.post(`partner/${id}/discount-code`, {
                discount,
                operation
            });

            return response;
        } catch (error) {
            console.error('Error updating partner discount code:', error);
            throw error;
        }
    }
};
