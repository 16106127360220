<template>
    <div>
        <div v-if="!withoutFilters" class="mb-2 d-flex flex-column flex-sm-row justify-content-center align-items-center">
            <div class="form-group mr-0 mr-sm-2 mb-2 mb-sm-0 w-100">
                <div class="search-bar w-100">
                    <span id="search-addon" class="input-group-text border-0 bg-transparent">
                        <i class="fas fa-search" />
                    </span>
                    <input
                        :disabled="isLoading"
                        v-model="searchTerm"
                        :placeholder="'Search by order id, user id/email/name, test name, shopify/stripe order/price/product id'"
                        class="border-0" />
                </div>
            </div>

            <select id="select" v-model="orderStatus" name="select" class="custom-select mr-2" :disabled="isLoading">
                <option :value="null" disabled>Select a status</option>
                <option :value="null" selected>Any Status</option>
                <option v-for="status in statuses" :key="status.value" :value="status.value">{{ status.label }}</option>
            </select>
        </div>
        <div v-if="!withoutFilters" class="my-2">
            <label style="font-size: 1rem; display: flex; align-items: center">
                <input v-model="outliersOnly" type="checkbox" :disabled="isLoading" style="margin-right: 0.5rem" @change="changeOutliersOnly" />
                Show outliers only
            </label>
        </div>
        <loader :listen="['user/getAllPatients', 'order/getAllOrders', 'order/getAllUserOrders']">
            <div v-if="total && !noPagination" class="text-left text-lg mt-4 font-weight-bold mb-2">Total Orders: {{ total }}</div>
            <b-table
                id="orders-table"
                striped
                stacked="lg"
                hover
                :items="orders"
                :fields="fields"
                :per-page="0"
                :current-page="!noPagination ? currentPage : 1"
                show-empty>
                <!-- <template #cell(i)="row">
                    <td :class="{ 'bg-danger': row.item.status === ORDER_STATUS.INVALID }">
                    </td>
                </template> -->
                <template #cell(basicInfo)="row">
                    <div class="card flex-column flex-lg-row p-1 py-2 py-lg-1 m-0">
                        <div class="col-auto col-lg">
                            <div class="secondary-label small">Order Info</div>
                            <div class="small">
                                <small>
                                    <b>ID:</b> {{ row.item.id }} <br />
                                    <b>Name:</b> {{ row.item?.testName }} <br />
                                </small>
                            </div>
                        </div>
                        <div class="col-auto col-lg">
                            <div class="secondary-label small">Other</div>
                            <div class="small d-flex flex-column">
                                <small v-if="row.item.source && row.item.source !== 'shopify'">{{ row.item.source }}</small>
                                <small v-if="row.item.withoutReview || row.item.withoutVenousDraw || row.item.withClinic"
                                    ><small class="text-info">
                                        <b v-if="row.item.withoutReview">WITHOUT DOCTOR REVIEW</b> <br v-if="row.item.withoutReview" />
                                        <b v-if="!row.item.withClinic && row.item.withoutVenousDraw">WITHOUT VENOUS DRAW</b>
                                        <b v-if="row.item.withClinic">WITH CLINIC</b>
                                    </small></small
                                >

                                <small v-if="row.item.source === 'shopify'"
                                    >(<b>Shopify ID:</b> {{ row.item.shopifyOrderId || 'not provided' }}; <b>Shopify Order Number:</b>
                                    {{ row.item.shopifyOrderNumber || 'not provided' }})</small
                                >

                                <small v-can-access.role="'admin'"
                                    ><small
                                        ><b v-if="row.item.referrerPartnerCode" class="text-success">PARTNER ORDER</b>
                                        <b v-can-access.role="'admin'" v-if="row.item.referrerPartnerCode">
                                            <router-link :to="{ name: 'partner-info', params: { partnerCode: row.item.referrerPartnerCode } }">
                                                (Partner Code: {{ row.item.referrerPartnerCode }})
                                            </router-link>
                                        </b></small
                                    >
                                </small>
                            </div>
                        </div>
                        <div class="col-auto col-lg">
                            <div class="secondary-label small">Tracking Info</div>
                            <div class="small">
                                <small>
                                    <!-- Check for trackingNumbers first -->
                                    <span v-if="row.item.trackingNumbers && row.item.trackingNumbers.length">
                                        <div v-for="(tracking, index) in row.item.trackingNumbers" :key="tracking.trackingNumber">
                                            <a :href="tracking.trackingUrl" target="_blank">
                                                {{ tracking.trackingNumber }} - {{ tracking.trackingCompany }}
                                            </a>
                                            <span v-if="index < row.item.trackingNumbers.length - 1">, </span>
                                        </div>
                                    </span>

                                    <!-- Fallback to mailingTrackingReference if trackingNumbers is not available -->
                                    <span v-else-if="row.item.mailingTrackingReference">
                                        <div>
                                            <b>Mail Reference (to the user):</b>
                                            <a
                                                v-for="(tracking, index) in row.item.mailingTrackingReference.split(',')"
                                                :key="tracking"
                                                target="_blank"
                                                :href="`https://www.royalmail.com/portal/rm/track?trackNumber=${tracking.trim()}`">
                                                {{ tracking }}
                                                <span v-if="index < row.item.mailingTrackingReference.split(',').length - 1">, </span>
                                            </a>
                                        </div>
                                    </span>

                                    <!-- Fallback to t24TrackingNumber if neither trackingNumbers nor mailingTrackingReference are available -->
                                    <span v-else-if="row.item.t24TrackingNumber">
                                        <div>
                                            <b>T24 Tracking (to the Lab):</b>
                                            <a
                                                v-for="(tracking, index) in row.item.t24TrackingNumber.split(',')"
                                                :key="tracking"
                                                target="_blank"
                                                :href="`https://www.royalmail.com/portal/rm/track?trackNumber=${tracking.trim()}`">
                                                {{ tracking }}
                                                <span v-if="index < row.item.t24TrackingNumber.split(',').length - 1">, </span>
                                            </a>
                                        </div>
                                    </span>

                                    <!-- Final fallback message if none of the tracking information is available -->
                                    <span v-else> Not available</span>
                                </small>
                            </div>
                        </div>
                    </div>
                </template>
                <template #cell(statusInfo)="row">
                    <small v-if="row.item.resolution"
                        ><b class="text-danger">Resolution:</b> <b>{{ getResolutionText(row.item.resolution) }}</b></small
                    >
                    <div
                        class="card flex-column p-2 px-3 small"
                        :class="
                            row.item?.outlierInformation?.isOutlier
                                ? row.item?.outlierInformation?.sanityCheck
                                    ? 'bg-outlier yellow'
                                    : 'bg-outlier'
                                : null
                        ">
                        <small v-if="row.item.appointmentNotes"
                            ><b>Appointment Req. Notes:</b> {{ row.item.appointmentNotes || 'No notes' }} <br
                        /></small>
                        <hr v-if="row.item.appointmentNotes" class="my-1 p-0" />
                        <div class="d-flex align-items-center">
                            <span class="font-weight-bold mr-2"> Status: </span>
                            <span class="analysis-title analysis-title__status small px-3" :class="getStatusColor(row.item.status)">{{
                                row.item.displayStatus
                            }}</span>
                        </div>

                        <small>
                            <div>
                                <span
                                    v-if="row.item.displayLastUpdatedDate && row.item.displayLastUpdatedDate !== row.item.displayPlacedDate"
                                    class="font-weight-bold">
                                    Last Update: </span
                                >{{ row.item.displayLastUpdatedDate }}
                            </div>
                            <div><span class="font-weight-bold"> Placed Date: </span>{{ row.item.displayPlacedDate }}</div>
                        </small>

                        <small class="m-0 p-0 my-2 d-flex flex-column align-items-start w-100">
                            <b-button size="sm" variant="link" @click="toggleCollapse(row.item.id)" class="m-0 p-0">
                                <i :class="isCollapsed(row.item.id) ? 'fa fa-plus mr-1' : 'fa fa-minus mr-1'" />{{
                                    isCollapsed(row.item.id) ? 'Collapse Journey' : 'Expand Journey'
                                }}
                            </b-button>
                            <small class="w-100">
                                <span
                                    v-if="row.item?.outlierInformation?.isOutlier"
                                    class="analysis-title analysis-title__status small px-3 m-2 w-100"
                                    :class="row.item?.outlierInformation?.sanityCheck ? 'yellow' : 'red'">
                                    <div class="d-flex flex-column align-items-start" style="text-align: left">
                                        <div>{{ row.item?.outlierInformation?.sanityCheck ? 'POTENTIAL OUTLIER' : 'OUTLIER' }}</div>

                                        <div v-if="row.item?.outlierInformation?.expectedStatus">
                                            <span class="font-weight-bold"> Expected Status: </span
                                            >{{ row.item?.outlierInformation?.expectedStatusDisplay }}
                                        </div>
                                        <div v-if="row.item?.outlierInformation?.expectedDate">
                                            <span class="font-weight-bold"> Expected Date: </span> {{ row.item?.outlierInformation?.expectedDate }}
                                        </div>
                                        <div v-if="row.item?.outlierInformation?.outlierReason">
                                            <span class="font-weight-bold"> Reasoning: </span> {{ row.item?.outlierInformation?.outlierReason }}
                                        </div>
                                    </div>
                                </span>
                            </small>
                        </small>
                        <b-collapse :id="'collapse-' + row.item.id" v-model="collapsedRows[row.item.id]">
                            <small class="card flex-column p-2">
                                <div class="mb-1"><span class="font-weight-bold"> Placed: </span>{{ row.item.displayPlacedDate }}</div>
                                <div class="mb-1">
                                    <span class="font-weight-bold"> Dispatched: </span>{{ row.item.displayDispatchDate || 'Not dispatched' }}
                                </div>
                                <div v-if="row.item.displaySchedulingPhlebotomyDate" class="mb-1">
                                    <span class="font-weight-bold"> Scheduling Phleb.: </span
                                    >{{ row.item.displaySchedulingPhlebotomyDate || 'Not Scheduled' }}
                                </div>
                                <div v-if="row.item.phlebotomyScheduleDateTime" class="mb-1">
                                    <span class="font-weight-bold"> Phleb. Scheduled: </span
                                    >{{ row.item.phlebotomyScheduleDateTime || 'Not Scheduled' }}
                                </div>
                                <div v-if="row.item.displayPhlebotomyDoneDateTime" class="mb-1">
                                    <span class="font-weight-bold"> Phleb. Done: </span>{{ row.item.displayPhlebotomyDoneDateTime || 'Not Done' }}
                                </div>
                                <div class="mb-1" v-if="row.item.displayDispatchedToLabDate">
                                    <span class="font-weight-bold"> Dispatched to Lab: </span
                                    >{{ row.item.displayDispatchedToLabDate || 'Not dispatched' }}
                                </div>
                                <div class="mb-1">
                                    <span class="font-weight-bold"> Lab Received: </span>{{ row.item.displayLabReceivedDate || 'Not received' }}
                                </div>
                                <div class="mb-1">
                                    <span class="font-weight-bold"> Lab Results: </span>{{ row.item.displayLabResultDate || 'No results' }}
                                </div>
                                <div>
                                    <span class="font-weight-bold"> Completed: </span>{{ row.item.displayOrderFinishedDate || 'Not completed' }}
                                </div>
                            </small>
                        </b-collapse>
                    </div>
                </template>
                <template v-if="!withoutPatient" #cell(patientInfo)="row">
                    <div class="d-flex w-100 align-items-center small">
                        <img :src="row.item.user?.profilePictureUrl" class="rounded-circle" width="40" height="40" />
                        <div class="d-flex flex-column w-100 ml-2">
                            <div>
                                <span class="font-weight-bold"> {{ ORDERS_TABLE.nameLabel }} </span>{{ row.item.user?.name }}
                            </div>
                            <small
                                ><small>
                                    <span class="font-weight-bold"> {{ ORDERS_TABLE.idLabel }} </span>{{ row.item.user.id }}
                                    <div><span class="font-weight-bold"> Email: </span>{{ row.item.user?.email }}</div>
                                    <div><span class="font-weight-bold"> Shipping Phone: </span>{{ row.item.shipping?.phone || 'Not provided' }}</div>
                                    <!-- <div>
                                        <span class="font-weight-bold"> Personal Info: </span> {{ row.item.user.gender }} |
                                        {{ row.item.user.age ? row.item.user.age + ` yo (${row.item.user.dob})` : 'Not provided' }}
                                    </div> -->
                                    <span v-can-access.role="'admin partner-plus'" v-if="row.item.additionalInformation?.length" class="mt-1">
                                        <span @click="addNotesModal(row.item)" class="analysis-title analysis-title__status small blue px-2">
                                            ORDER HAS NOTES
                                        </span>
                                    </span>
                                </small>
                            </small>
                        </div>
                    </div>
                </template>
                <template #cell(actions)="row">
                    <b-dropdown
                        id="dropdown-grouped"
                        text="Actions"
                        class="mx-2"
                        variant="primary"
                        :disabled="
                            $store.state.loading[`order/updateOrderStatus/${row.item.id}`] ||
                            $store.state.loading[`order/generateQRCode/${row.item.id}`] ||
                            $store.state.loading[`order/generateFormOrLabelForOrders/${row.item.id}`]
                        ">
                        <b-dropdown-item-button v-if="!withoutPatient" @click="viewUser(row.item.user.id)">View user</b-dropdown-item-button>
                        <b-dropdown-item-button v-if="row.item.results" @click="viewResult(row.item)">View result</b-dropdown-item-button>
                        <b-dropdown-divider v-if="row.item.status !== ORDER_STATUS.INVALID"></b-dropdown-divider>
                        <b-dropdown-group
                            v-can-access.role="'admin'"
                            v-if="row.item.status !== ORDER_STATUS.INVALID || row.item.status !== ORDER_STATUS.CANCELED"
                            id="dropdown-group-1"
                            header="Change Status">
                            <b-dropdown-item-button
                                v-can-access.role="'admin'"
                                v-if="row.item.status !== ORDER_STATUS.INVALID"
                                @click="adjustOrderStatus(row.item.id, row.item.user.id, ORDER_STATUS.INVALID)">
                                Status to invalid
                            </b-dropdown-item-button>
                            <b-dropdown-item-button
                                v-can-access.role="'admin'"
                                v-if="row.item.status !== ORDER_STATUS.CANCELED"
                                @click="adjustOrderStatus(row.item.id, row.item.user.id, ORDER_STATUS.CANCELED)">
                                Status to canceled
                            </b-dropdown-item-button>
                        </b-dropdown-group>
                        <b-dropdown-divider v-can-access.role="'admin partner-plus'"></b-dropdown-divider>
                        <b-dropdown-group v-can-access.role="'admin partner-plus'" id="dropdown-group-1" header="Other">
                            <b-dropdown-item-button v-can-access.role="'admin partner-plus'" @click="textInfoModal(row.item)">
                                Shipping Info
                            </b-dropdown-item-button>
                            <b-dropdown-item-button v-can-access.role="'admin partner-plus'" @click="addNotesModal(row.item)">
                                Amend Notes
                            </b-dropdown-item-button>
                            <b-dropdown-item-button v-can-access.role="'admin partner-plus'" @click="generateFile(row.item, 'lab-form')">
                                Download Lab Form
                            </b-dropdown-item-button>
                            <b-dropdown-item-button v-can-access.role="'admin partner-plus'" @click="generateFile(row.item, 'tube-labels')">
                                Download Tube Labels
                            </b-dropdown-item-button>
                            <b-dropdown-item-button v-can-access.role="'admin'" @click="generateQRCodeForOrder(row.item)">
                                Generate Lab QR
                            </b-dropdown-item-button>
                            <!-- <b-dropdown-item-button v-can-access.role="'admin'" @click="generateKitDispatchCSV(row.item)">
                                Generate Kit Dispatch CSV
                            </b-dropdown-item-button> -->
                            <b-dropdown-item-button
                                v-if="!row.item.referrerPartnerCode"
                                v-can-access.role="'admin'"
                                @click="connectToPartner(row.item)">
                                Connect to partner
                            </b-dropdown-item-button>
                        </b-dropdown-group>
                    </b-dropdown>
                </template>
            </b-table>
            <b-pagination
                v-if="!noPagination"
                v-model="currentPage"
                align="center"
                :total-rows="total"
                :per-page="pageSize"
                aria-controls="orders-table"
                @change="handlePageChange" />
        </loader>
        <slot v-if="enableAddNotesModal || enableKitDispatchModal || enableTextInfoModal" for="modals">
            <OrderAddNotesModal
                :show-modal="enableAddNotesModal"
                :order="inViewOrder"
                :title="`Add notes/info to this order`"
                :description="'Eg. Particular/Lola conditions or medications'"
                @close="
                    enableAddNotesModal = false;
                    inViewOrder = null;
                " />
            <KitDispatchOrdersModal
                :show-modal="enableKitDispatchModal"
                :order-ids="[inViewOrder?.id]"
                @close="
                    enableKitDispatchModal = false;
                    inViewOrder = null;
                " />
            <TextInfoModal
                :show-modal="enableTextInfoModal"
                :text="inViewOrderShippingText"
                :title="`Shipping info`"
                :description="''"
                @close="
                    enableTextInfoModal = false;
                    inViewOrder = null;
                    inViewOrderShippingText = null;
                "></TextInfoModal>
        </slot>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ORDERS_TABLE, ORDER_STATUS } from '@/utils/constants';
import moment from 'moment';
import OrderAddNotesModal from './Modals/order-add-notes-modal.vue';
import KitDispatchOrdersModal from './Modals/kit-dispatch-orders-modal.vue';
import TextInfoModal from './Modals/text-info-modal.vue';
import { getResolutionText } from '@/utils/utils';

export default {
    components: {
        OrderAddNotesModal,
        KitDispatchOrdersModal,
        TextInfoModal
    },
    props: {
        orders: {
            type: Array,
            default: () => []
        },
        withoutFilters: {
            type: Boolean,
            default: false
        },
        withoutPatient: {
            type: Boolean,
            default: false
        },
        noPagination: {
            type: Boolean,
            default: false
        }
    },
    data() {
        const statuses = ORDER_STATUS;

        return {
            ORDERS_TABLE,
            ORDER_STATUS,
            fields: [{ key: 'basicInfo' }, { key: 'statusInfo' }, { key: 'patientInfo' }, { key: 'actions' }], //{ key: 'i' },
            currentPage: 1,
            perPage: 10,
            searchTerm: null,
            selectedUser: null,
            orderStatus: null,
            debouncedSearch: null,
            statuses: [
                {
                    value: statuses.PLACED,
                    label: 'Placed'
                },
                {
                    value: statuses.DISPATCHED,
                    label: 'Dispatched'
                },
                {
                    value: statuses.DISPATCHED_TO_LAB,
                    label: 'Dispatched to Lab'
                },
                {
                    value: statuses.RECEIVED_BY_LAB,
                    label: 'Received by Lab'
                },
                {
                    value: statuses.REVIEW,
                    label: 'In Review'
                },
                {
                    value: statuses.COMPLETED,
                    label: 'Completed'
                },
                {
                    value: statuses.INVALID,
                    label: 'Invalid'
                },
                {
                    value: statuses.CANCELED,
                    label: 'Canceled'
                }
            ],
            collapsedRows: {},
            collapseAll: false,
            expandAll: false,
            enableAddNotesModal: false,
            enableKitDispatchModal: false,
            enableTextInfoModal: false,
            inViewOrder: null,
            inViewOrderShippingText: null,
            outliersOnly: false
        };
    },
    watch: {
        searchTerm(value) {
            this.debouncedSearch(value);
        },
        queryString: {
            handler(value) {
                this.searchTerm = value;
            },
            deep: true,
            immediate: true
        },
        orderStatus: {
            handler(value) {
                this.emitStatus(value);
            }
        },
        collapseAll(newValue) {
            if (newValue) {
                this.toggleCollapseAll();
            }
        },
        expandAll(newValue) {
            if (newValue) {
                this.toggleExpandAll();
            }
        },
        orders: {
            handler() {
                this.inViewOrder = this.inViewOrder ? this.orders?.find((order) => order.id === this.inViewOrder?.id) : null;
            }
        }
    },
    computed: {
        ...mapGetters({
            loggedUser: 'user/currentUser',
            page: 'order/page',
            pageSize: 'order/pageSize',
            total: 'order/total',
            status: 'order/status',
            queryString: 'order/queryString'
        }),
        isLoading() {
            return (
                !!this.$store.state.loading['user/getAllPatients'] ||
                !!this.$store.state.loading['order/getAllOrders'] ||
                !!this.$store.state.loading['order/getAllUserOrders']
            );
        },
        isProduction() {
            return process.env.VUE_APP_ENVIRONMENT === 'production';
        }
    },
    created() {
        if (this.withoutPatient) {
            this.fields = [{ key: 'basicInfo' }, { key: 'statusInfo' }, { key: 'actions' }];
        }

        this.debouncedSearch = _.debounce(this.emitSearch, 700);

        this.currentPage = this.page;
        this.orderStatus = this.status;
    },
    methods: {
        ...mapActions({
            updateOrderStatus: 'order/updateOrderStatus',
            generateQRCode: 'order/generateQRCode'
        }),
        beautifyDate(date) {
            return date ? moment(date).format('DD MMM YYYY') : '';
        },
        goToOrder(order) {
            this.$router.push({
                name: 'order',
                params: { orderId: order.id },
                query: { userId: order?.user?.id || order.userId }
            });
        },
        viewUser(userId) {
            this.$emit('viewUser', userId);
        },
        viewResult(order) {
            this.$emit('viewResult', { orderId: order.id, userId: order?.user?.id || order.userId });
        },
        async handlePageChange(page) {
            this.currentPage = page;

            this.$emit('pageChange', { page, status: this.orderStatus, queryString: this.searchTerm, outliers: this.outliersOnly });
        },
        emitSearch(value) {
            this.searchTerm = value;

            this.currentPage = 1;
            this.$emit('search', value);
        },
        emitStatus(value) {
            this.currentPage = 1;

            this.$emit('pageChange', { page: this.currentPage, status: value, queryString: this.searchTerm, outliers: this.outliersOnly });
        },
        getStatusColor(status) {
            switch (status) {
                case ORDER_STATUS.PLACED:
                    return 'blue';
                case ORDER_STATUS.DISPATCHED:
                    return 'yellow';
                case ORDER_STATUS.DISPATCHED_TO_LAB:
                    return 'yellow';
                case ORDER_STATUS.RECEIVED_BY_LAB:
                    return 'yellow';
                case ORDER_STATUS.REVIEW:
                    return 'yellow';
                case ORDER_STATUS.INVALID:
                    return 'red';
                case ORDER_STATUS.CANCELED:
                    return 'grey';
                case ORDER_STATUS.SCHEDULING_PHLEBOTOMY:
                    return 'yellow';
                case ORDER_STATUS.PHLEBOTOMY_SCHEDULED:
                    return 'blue';
                case ORDER_STATUS.PHLEBOTOMY_DONE:
                    return 'green';
                default:
                    return 'green';
            }
        },
        async adjustOrderStatus(orderId, userId, status = '') {
            if (!status) {
                console.error('No status provided');
                return;
            }

            this.$confirm({
                message: `Are you sure you want to set the status of the order as '${status}'?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        try {
                            await this.updateOrderStatus({ id: orderId, status, userId });

                            this.$notify({
                                type: 'success',
                                text: 'Order status updated successfully'
                            });
                        } catch (e) {
                            console.error('Error updating order status:', e);
                        }
                    }
                }
            });
        },
        toggleCollapse(id) {
            this.$set(this.collapsedRows, id, !this.collapsedRows[id]);
        },
        isCollapsed(id) {
            return this.collapsedRows[id];
        },
        toggleCollapseAll() {
            this.expandAll = false;
            this.orders.forEach((order) => {
                this.$set(this.collapsedRows, order.id, true);
            });
            this.collapseAll = true;
        },
        toggleExpandAll() {
            this.collapseAll = false;
            this.orders.forEach((order) => {
                this.$set(this.collapsedRows, order.id, false);
            });
            this.expandAll = true;
        },
        toggleCollapseExpandAll() {
            if (this.collapseAll) {
                this.toggleExpandAll();
            } else {
                this.toggleCollapseAll();
            }
        },
        addNotesModal(order) {
            this.enableAddNotesModal = true;
            this.inViewOrder = order;
        },
        textInfoModal(order) {
            this.enableTextInfoModal = true;

            let address = `Name: ${order.shipping?.name || 'N/A'}\n`;
            address = address.concat(`Phone: ${order.shipping?.phone || 'N/A'}\n\n`);
            address = address.concat(`Line 1: ${order.shipping?.address?.line1 || 'N/A'}\n`);
            address = address.concat(`Line 2: ${order.shipping?.address?.line2 || 'N/A'}\n`);
            address = address.concat(`City: ${order.shipping?.address?.city || 'N/A'}\n`);
            address = address.concat(`Postal Code: ${order.shipping?.address?.postal_code || 'N/A'}\n`);
            address = address.concat(`State: ${order.shipping?.address?.state || 'N/A'}\n`);
            address = address.concat(`Country: ${order.shipping?.address?.country || 'N/A'}\n`);

            this.inViewOrderShippingText = address;
        },
        async changeOutliersOnly() {
            this.currentPage = 1;

            this.$emit('pageChange', { page: this.currentPage, outliers: this.outliersOnly, status: this.orderStatus, queryString: this.searchTerm });
        },
        async generateKitDispatchCSV(order) {
            this.enableKitDispatchModal = true;
            this.inViewOrder = order;
        },
        async generateQRCodeForOrder(order) {
            try {
                const buffer = await this.generateQRCode(order.id);

                // Create a blob from the response data
                const blob = new Blob([buffer], { type: 'image/png' });

                // Create a link element
                const link = document.createElement('a');

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);
                link.href = url;

                // Set the download attribute with the filename
                link.setAttribute('download', `QR-${order.id}.png`);

                // Append the link to the body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Clean up the URL and remove the link element
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error downloading QR code:', error);
            }
        },
        connectToPartner(order) {
            this.$emit('connectToPartner', order);
        },
        getResolutionText,
        generateFile(order, resource = 'lab-form') {
            this.$emit('generateFile', { order, resource });
        }
    }
};
</script>
