/* eslint-disable class-methods-use-this */
// import moment from 'moment';
import moment from 'moment';
import Model from './model';
import UserModel from './user.model';
import ResultModel from './result.model';

export default class OrderModel extends Model {
    get displayPlacedDate() {
        return this.attributes.placedDate ? moment(this.attributes.placedDate).local().format('DD MMM YYYY HH:mm') : null;
    }

    get displayDispatchDate() {
        return this.attributes.dispatchDate ? moment(this.attributes.dispatchDate).local().format('DD MMM YYYY HH:mm') : null;
    }

    get displaySchedulingPhlebotomyDate() {
        return this.attributes.schedulingPhlebotomyDate ? moment(this.attributes.schedulingPhlebotomyDate).local().format('DD MMM YYYY') : null;
    }

    get displayPhlebotomyScheduleDateTime() {
        return this.attributes.phlebotomyScheduleDateTime
            ? moment(this.attributes.phlebotomyScheduleDateTime).local().format('DD MMM YYYY HH:mm')
            : null;
    }

    get displayPhlebotomyDoneDateTime() {
        return this.attributes.phlebotomyDoneDateTime ? moment(this.attributes.phlebotomyDoneDateTime).local().format('DD MMM YYYY HH:mm') : null;
    }

    get displayLabReceivedDate() {
        return this.attributes.labReceivedDate ? moment(this.attributes.labReceivedDate).local().format('DD MMM YYYY HH:mm') : null;
    }

    get displayLabResultDate() {
        return this.attributes.labResultDate ? moment(this.attributes.labResultDate).local().format('DD MMM YYYY HH:mm') : null;
    }

    get displayOrderFinishedDate() {
        return this.attributes.orderFinishedDate ? moment(this.attributes.orderFinishedDate).local().format('DD MMM YYYY HH:mm') : null;
    }

    get displayDispatchedToLabDate() {
        return this.attributes.dispatchedToLabDate ? moment(this.attributes.dispatchedToLabDate).local().format('DD MMM YYYY HH:mm') : null;
    }

    get displayCanceledDate() {
        return this.attributes.canceledDate ? moment(this.attributes.canceledDate).local().format('DD MMM YYYY HH:mm') : null;
    }

    get displayInvalidDate() {
        return this.attributes.invalidDate ? moment(this.attributes.invalidDate).local().format('DD MMM YYYY HH:mm') : null;
    }

    get displayLastUpdatedDate() {
        return this.attributes.lastUpdatedDate ? moment(this.attributes.lastUpdatedDate).local().format('DD MMM YYYY HH:mm') : null;
    }

    get user() {
        return this.attributes.user ? new UserModel(this.attributes.user) : null;
    }

    get result() {
        return this.attributes.result ? new ResultModel(this.attributes.result) : null;
    }

    get withoutReview() {
        return this.attributes.withoutReview;
    }

    get withoutVenousDraw() {
        return this.attributes.withoutVenousDraw;
    }

    get withClinic() {
        return this.attributes.withClinic;
    }

    get displayStatus() {
        const status = this.attributes.status.replaceAll('_', ' ');

        return status.charAt(0).toUpperCase() + status.slice(1);
    }

    get outlierInformation() {
        const expectedStatusDisplay = this.attributes.outlierInformation?.expectedStatus?.replaceAll('_', ' ');

        return {
            ...this.attributes.outlierInformation,
            expectedStatusDisplay: expectedStatusDisplay ? expectedStatusDisplay.charAt(0).toUpperCase() + expectedStatusDisplay.slice(1) : null
        };
    }

    get status() {
        return this.attributes.status;
    }

    get mailingTrackingReference() {
        return this.attributes.mailingTrackingReference && Array.isArray(this.attributes.mailingTrackingReference)
            ? this.attributes.mailingTrackingReference.join(', ')
            : this.attributes.mailingTrackingReference;
    }

    get t24TrackingNumber() {
        return this.attributes.t24TrackingNumber && Array.isArray(this.attributes.t24TrackingNumber)
            ? this.attributes.t24TrackingNumber.join(', ')
            : this.attributes.t24TrackingNumber;
    }

    get trackingNumbers() {
        return this.attributes.trackingNumbers;
    }
}
