import api from '../api/partner.api';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        async acceptPartnerInvitation({ commit }, { token, response }) {
            commit('activateLoading', 'partner/acceptPartnerInvitation', { root: true });
            try {
                const beResponse = await api.acceptPartnerInvitation({ token, response });

                return beResponse;
            } finally {
                commit('deactivateLoading', 'partner/acceptPartnerInvitation', { root: true });
            }
        },
        async createPartnerConnection({ commit, dispatch }, { partnerCode, resource, resourceId } = {}) {
            commit('activateLoading', `partner/createPartnerConnection/${partnerCode}`, { root: true });
            commit('activateLoading', 'partner/createPartnerConnection', { root: true });
            try {
                const response = await api.createPartnerConnection({ partnerCode, resource, resourceId });

                if (resource === 'userId') {
                    dispatch('user/getAllPatients', { withLoading: false }, { root: true });
                }

                if (resource === 'orderId') {
                    dispatch('order/getAllOrders', { withLoading: false }, { root: true });
                }

                return response;
            } finally {
                commit('deactivateLoading', `partner/createPartnerConnection/${partnerCode}`, { root: true });
                commit('deactivateLoading', 'partner/createPartnerConnection', { root: true });
            }
        },
        async deletePartnerConnectionFromAUser({ commit, dispatch }, { partnerCode, userId }) {
            commit('activateLoading', 'partner/deletePartnerConnectionFromAUser', { root: true });
            try {
                await api.deletePartnerConnectionFromAUser({ partnerCode, userId });

                dispatch('user/getAllPatients', { withLoading: false }, { root: true });
            } finally {
                commit('deactivateLoading', 'partner/deletePartnerConnectionFromAUser', { root: true });
            }
        },
        async getPartner({ commit }, partnerCode) {
            commit('activateLoading', 'partner/getPartner', { root: true });
            commit('activateLoading', `partner/getPartner/${partnerCode}`, { root: true });
            try {
                const response = await api.getPartner(partnerCode);

                commit('user/setUserById', response, { root: true });

                return response;
            } finally {
                commit('deactivateLoading', 'partner/getPartner', { root: true });
                commit('deactivateLoading', `partner/getPartner/${partnerCode}`, { root: true });
            }
        },
        async createPartner({ commit, dispatch }, { name, email, role, actions, partnerCouponCode, discountPercentage, sendInvitationEmail } = {}) {
            commit('activateLoading', 'partner/createPartner', { root: true });
            try {
                const response = await api.createPartner({
                    name,
                    email,
                    role,
                    actions,
                    partnerCouponCode,
                    discountPercentage,
                    sendInvitationEmail
                });

                // Fetch updated partner list after creation
                await dispatch('user/getAllPartners', { withLoading: false }, { root: true });

                return response;
            } finally {
                commit('deactivateLoading', 'partner/createPartner', { root: true });
            }
        },
        async updatePartner({ commit, dispatch }, { id, name, email, role, actions, discountPercentage }) {
            commit('activateLoading', 'partner/updatePartner', { root: true });
            commit('activateLoading', `partner/updatePartner/${id}`, { root: true });
            try {
                const response = await api.updatePartner(id, {
                    name,
                    email,
                    role,
                    actions,
                    // partnerCouponCode,
                    discountPercentage
                });

                // Fetch updated partner list after update
                await dispatch('user/getAllPartners', { withLoading: false }, { root: true });

                return response;
            } finally {
                commit('deactivateLoading', 'partner/updatePartner', { root: true });
                commit('deactivateLoading', `partner/updatePartner/${id}`, { root: true });
            }
        },
        async updatePartnerDiscountCode({ commit, dispatch }, { id, discount, operation }) {
            commit('activateLoading', `partner/updatePartnerDiscountCode/${id}`, { root: true });
            commit('activateLoading', 'partner/updatePartnerDiscountCode', { root: true });
            try {
                const response = await api.updatePartnerDiscountCode({
                    id,
                    discount,
                    operation
                });

                // Fetch updated partner list after update
                await dispatch('user/getAllPartners', { withLoading: false }, { root: true });

                return response;
            } finally {
                commit('deactivateLoading', `partner/updatePartnerDiscountCode/${id}`, { root: true });
                commit('deactivateLoading', 'partner/updatePartnerDiscountCode', { root: true });
            }
        }
    }
};
