/* eslint-disable import/no-cycle */
import Vue from 'vue';
import api from '../api/dashboard.api';
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        dashboardBasicMetrics: {}
    },
    getters: {
        dashboardBasicMetrics(state) {
            return state.dashboardBasicMetrics;
        }
    },
    mutations: {
        setDashboardBasicMetrics(state, metrics) {
            Vue.set(state, 'dashboardBasicMetrics', metrics);
        }
    },
    actions: {
        async getDashboardBasicMetrics({ commit, state }, { filter, forceRefresh, withoutLoading, exportToCSV, partnerCode } = {}) {
            if (!withoutLoading && !exportToCSV) {
                if (!partnerCode) commit('activateLoading', 'dashboard/getDashboardBasicMetrics', { root: true });
                else commit('activateLoading', `dashboard/getDashboardBasicMetrics/${partnerCode}`, { root: true });
            }
            if (exportToCSV && !partnerCode) commit('activateLoading', 'dashboard/exportMetricsToCSV', { root: true });
            if (exportToCSV && partnerCode) commit('activateLoading', `dashboard/exportMetricsToCSV/${partnerCode}`, { root: true });
            try {
                if (!exportToCSV && !partnerCode && filter.value === 'all-time' && !withoutLoading) {
                    if (!_.isEmpty(state.dashboardBasicMetrics) && !forceRefresh && state.dashboardBasicMetrics?.filter?.value === 'all-time') {
                        return state.dashboardBasicMetrics;
                    }
                }

                const metrics = await api.getMetrics(filter, partnerCode, exportToCSV);

                if (!exportToCSV) commit('setDashboardBasicMetrics', metrics);

                return metrics;
            } finally {
                if (!withoutLoading && !exportToCSV) {
                    if (!partnerCode) commit('deactivateLoading', 'dashboard/getDashboardBasicMetrics', { root: true });
                    else commit('deactivateLoading', `dashboard/getDashboardBasicMetrics/${partnerCode}`, { root: true });
                }

                if (exportToCSV && !partnerCode) commit('deactivateLoading', 'dashboard/exportMetricsToCSV', { root: true });
                if (exportToCSV && partnerCode) commit('deactivateLoading', `dashboard/exportMetricsToCSV/${partnerCode}`, { root: true });
            }
        }
    }
};
